const LoadingButtonSpinner = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      style={{
        margin: "auto",
        display: "block",
        shapeRendering: "auto",
      }}
      width="27px"
      height="27px"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <g transform="translate(80,50)">
        <g transform="rotate(0)">
          <circle cx="0" cy="0" r="6" fill="#ffffff" fillOpacity="1">
            <animateTransform
              attributeName="transform"
              type="scale"
              begin="-0.8413461538461539s"
              values="1.5 1.5;1 1"
              keyTimes="0;1"
              dur="0.9615384615384615s"
              repeatCount="indefinite"
            ></animateTransform>
            <animate
              attributeName="fillOpacity"
              keyTimes="0;1"
              dur="0.9615384615384615s"
              repeatCount="indefinite"
              values="1;0"
              begin="-0.8413461538461539s"
            ></animate>
          </circle>
        </g>
      </g>
      <g transform="translate(71.21320343559643,71.21320343559643)">
        <g transform="rotate(45)">
          <circle cx="0" cy="0" r="6" fill="#ffffff" fillOpacity="0.875">
            <animateTransform
              attributeName="transform"
              type="scale"
              begin="-0.7211538461538461s"
              values="1.5 1.5;1 1"
              keyTimes="0;1"
              dur="0.9615384615384615s"
              repeatCount="indefinite"
            ></animateTransform>
            <animate
              attributeName="fillOpacity"
              keyTimes="0;1"
              dur="0.9615384615384615s"
              repeatCount="indefinite"
              values="1;0"
              begin="-0.7211538461538461s"
            ></animate>
          </circle>
        </g>
      </g>
      <g transform="translate(50,80)">
        <g transform="rotate(90)">
          <circle cx="0" cy="0" r="6" fill="#ffffff" fillOpacity="0.75">
            <animateTransform
              attributeName="transform"
              type="scale"
              begin="-0.6009615384615384s"
              values="1.5 1.5;1 1"
              keyTimes="0;1"
              dur="0.9615384615384615s"
              repeatCount="indefinite"
            ></animateTransform>
            <animate
              attributeName="fillOpacity"
              keyTimes="0;1"
              dur="0.9615384615384615s"
              repeatCount="indefinite"
              values="1;0"
              begin="-0.6009615384615384s"
            ></animate>
          </circle>
        </g>
      </g>
      <g transform="translate(28.786796564403577,71.21320343559643)">
        <g transform="rotate(135)">
          <circle cx="0" cy="0" r="6" fill="#ffffff" fillOpacity="0.625">
            <animateTransform
              attributeName="transform"
              type="scale"
              begin="-0.4807692307692307s"
              values="1.5 1.5;1 1"
              keyTimes="0;1"
              dur="0.9615384615384615s"
              repeatCount="indefinite"
            ></animateTransform>
            <animate
              attributeName="fillOpacity"
              keyTimes="0;1"
              dur="0.9615384615384615s"
              repeatCount="indefinite"
              values="1;0"
              begin="-0.4807692307692307s"
            ></animate>
          </circle>
        </g>
      </g>
      <g transform="translate(20,50.00000000000001)">
        <g transform="rotate(180)">
          <circle cx="0" cy="0" r="6" fill="#ffffff" fillOpacity="0.5">
            <animateTransform
              attributeName="transform"
              type="scale"
              begin="-0.3605769230769231s"
              values="1.5 1.5;1 1"
              keyTimes="0;1"
              dur="0.9615384615384615s"
              repeatCount="indefinite"
            ></animateTransform>
            <animate
              attributeName="fillOpacity"
              keyTimes="0;1"
              dur="0.9615384615384615s"
              repeatCount="indefinite"
              values="1;0"
              begin="-0.3605769230769231s"
            ></animate>
          </circle>
        </g>
      </g>
      <g transform="translate(28.78679656440357,28.786796564403577)">
        <g transform="rotate(225)">
          <circle cx="0" cy="0" r="6" fill="#ffffff" fillOpacity="0.375">
            <animateTransform
              attributeName="transform"
              type="scale"
              begin="-0.24038461538461536s"
              values="1.5 1.5;1 1"
              keyTimes="0;1"
              dur="0.9615384615384615s"
              repeatCount="indefinite"
            ></animateTransform>
            <animate
              attributeName="fillOpacity"
              keyTimes="0;1"
              dur="0.9615384615384615s"
              repeatCount="indefinite"
              values="1;0"
              begin="-0.24038461538461536s"
            ></animate>
          </circle>
        </g>
      </g>
      <g transform="translate(49.99999999999999,20)">
        <g transform="rotate(270)">
          <circle cx="0" cy="0" r="6" fill="#ffffff" fillOpacity="0.25">
            <animateTransform
              attributeName="transform"
              type="scale"
              begin="-0.12019230769230768s"
              values="1.5 1.5;1 1"
              keyTimes="0;1"
              dur="0.9615384615384615s"
              repeatCount="indefinite"
            ></animateTransform>
            <animate
              attributeName="fillOpacity"
              keyTimes="0;1"
              dur="0.9615384615384615s"
              repeatCount="indefinite"
              values="1;0"
              begin="-0.12019230769230768s"
            ></animate>
          </circle>
        </g>
      </g>
      <g transform="translate(71.21320343559643,28.78679656440357)">
        <g transform="rotate(315)">
          <circle cx="0" cy="0" r="6" fill="#ffffff" fillOpacity="0.125">
            <animateTransform
              attributeName="transform"
              type="scale"
              begin="0s"
              values="1.5 1.5;1 1"
              keyTimes="0;1"
              dur="0.9615384615384615s"
              repeatCount="indefinite"
            ></animateTransform>
            <animate
              attributeName="fillOpacity"
              keyTimes="0;1"
              dur="0.9615384615384615s"
              repeatCount="indefinite"
              values="1;0"
              begin="0s"
            ></animate>
          </circle>
        </g>
      </g>
      {/* [ldio] generated by https://loading.io/  */}
    </svg>
  );
};

export default LoadingButtonSpinner;
