const PackageIcon = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      fill="#fff"
    >
      <g id="XMLID_1_">
        <path
          id="XMLID_8_"
          d="M256.9,0L1.9,96.8V417L256,512l254.1-95.9V96.8L256.9,0z M453.4,107.1l-77.3,28.9L179.7,61.4l77.3-28.9
       L453.4,107.1z M256,180.6L58.6,107.1l78.2-29.8l196.4,74.5L256,180.6z M31.7,128.5l209.5,78.2v267.2L31.7,395.6V128.5z
        M270.9,474.8V206.7l209.5-78.2v267.2L270.9,474.8z"
        />
      </g>
    </svg>
  );
};

export default PackageIcon;
